/*
 Palkkalajit
 Kuukausipalkka - 201
 Muu KTA (tunti) - 20111
 Koulutus - 201108
 Myyntityö (tunti) - 20112
 Laatupalkka (rahaa) - 20113
 Sairasloma (tunti) - 2011001
 Palkaton poissaolo - muu syy - 201299
 Arkipyhä (tunti) - 2161
 Palkaton vapaa voisi olla tili 20129 Palkaton poissaolo – muu syy

Palkaton loma voisi olla tili 201212 Palkaton poissaolo – vuosiloma
 Henkilönumero;Palkkalaji;Päivämäärä;Määrä;Hinta;Kerroin;Selite;Poissaolo alkaa;Poissaolo päättyy;Poissaolopäivät;Dimensio1;Dimensio2;Dimensio3;Dimensio4
 example: P11111;20111;07.10.2022;7,5;;;;;;;;;;
 */

import { convertTimeToFloat } from './helpers';

/*
Provisiolle ei ole omaa palkkaljia, vaan se voisi tulla erillisenä laatupalkkio-rivinä,
samalla tavalla kuin nyt kampanjoittain maksettavat laatupalkat.
 */

const activityTypes = {
  Kuukausipalkka: '201',
  'Muu KTA työ': '20111',
  Koulutus: '201108',
  Myyntityö: '20112',
  Laatupalkka: '20113',
  Sairasloma: '2011001',
  Arkipyhä: '2161',
  'Palkaton poissaolo - muu syy': '201299',
  'Palkaton loma': '201212',
};

const usersQualitySalary = [] // Array for users who have quality salary
const usersProvisionSalary = [] // Array for users who have provision salary

export const exportToCsv = (hourlyRate, data, campaigns, users, qualitySalaries, provisionSalaries) => {
  const csvData = [];
  csvData.push([
    'Henkilönumero',
    'Palkkalaji',
    'Päivämäärä',
    'Määrä',
    'Hinta',
    'Kerroin',
    'Selite',
    'Poissaolo alkaa',
    'Poissaolo päättyy',
    'Poissaolopäivät',
    'Dimensio1',
    'Dimensio2',
    'Dimensio3',
    'Dimensio4'
  ]);
  data.forEach((dataRow) => {
    const time = convertTimeToFloat(dataRow.Aika);

    if (!users[dataRow.Käyttäjätunnus]) return;

    if (!activityTypes[dataRow.Aktiviteetti]) {
      return;
    }
    const qualitySalary = qualitySalaries[dataRow.Käyttäjätunnus];
    const provisionSalary = provisionSalaries[dataRow.Käyttäjätunnus];
    console.log('user', dataRow.Käyttäjätunnus)
    console.log('provision salary',provisionSalary)

    if (qualitySalary[dataRow.Kampanja] > 0) {
      let filteredQualitySalary = usersQualitySalary.filter(user => {
        return user.user === users[dataRow.Käyttäjätunnus].employeeNumber
      })
      if (filteredQualitySalary.filter(user => {
        return user.campaign === dataRow.Kampanja
      }).length > 0) {
      } else {
        usersQualitySalary.push({
          user: users[dataRow.Käyttäjätunnus].employeeNumber,
          campaign: dataRow.Kampanja,
          salary: qualitySalary[dataRow.Kampanja],
          hours: qualitySalary['hours'][dataRow.Kampanja]
        });
      }
    }

    if (provisionSalary[dataRow.Kampanja] > 0) {
      let filteredProvisionSalary = usersProvisionSalary.filter(user => {
        return user.user === users[dataRow.Käyttäjätunnus].employeeNumber
      })
      if (filteredProvisionSalary.filter(user => {
        return user.campaign === dataRow.Kampanja
      }).length > 0) {
      } else {
        usersProvisionSalary.push({
          user: users[dataRow.Käyttäjätunnus].employeeNumber,
          campaign: dataRow.Kampanja,
          salary: provisionSalary[dataRow.Kampanja],
          hours: time
        });
      }
    }

    csvData.push([
      users[dataRow.Käyttäjätunnus].employeeNumber,
      activityTypes[dataRow.Aktiviteetti],
      dataRow.Date,
      time,
      '',
      '',
      dataRow.Kampanja ? `${dataRow.Aktiviteetti}: ${dataRow.Kampanja}  ${dataRow.Date}` : `${dataRow.Aktiviteetti} ${dataRow.Date}`
    ]);
  });

  if (usersProvisionSalary.length > 0) {
    usersProvisionSalary.forEach((user) => {
      csvData.push([
        user.user,
        activityTypes.Laatupalkka,
        '',
        '',
        user.salary,
        '',
        `Provisio: ${user.campaign}`,
        ''
      ]);
    });
  }

  if (usersQualitySalary.length > 0) {
    usersQualitySalary.forEach((user) => {
      csvData.push([
        user.user,
        activityTypes.Laatupalkka,
        '',
        user.hours,
        user.salary,
        '',
        `Laatupalkka: ${user.campaign}`,
        ''
      ]);
    });
  }
  csvData.sort((a, b) => a[0] - b[0]); // Sort by user id
  const csvContent = csvData.map((e) => e.join(';')).join('\n');
  const csvBlob = new Blob([csvContent], { type: 'text/csv' });
  const csvUrl = URL.createObjectURL(csvBlob);
  const link = document.createElement('a');
  link.href = csvUrl;
  link.setAttribute('download', 'data.csv');
  document.body.appendChild(link);
  link.click();
  link.remove();
};
